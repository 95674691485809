import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../../components/ModalProduct";
import { Link } from "react-router-dom";
import { images } from "../../../../../ImagePath";
import { imageUrl } from "../../../../../config";

const Row = ({ item }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={item.following.image ? `${imageUrl}/${item.following.image}` : images.logo} alt="Avatar" />
            </div>
            <div>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.following.name ?? 'x'}</div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.following.sector ?? 'x'}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.phone ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Company</div>
          {item.following.company ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>From</div>
          {item.from ?? 'x'}</div>

        <div className={styles.col}>
          <Link to={`/Viewprofile/${item.following.url}`} className={styles.details}>View</Link>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
