import React, { useEffect, useState } from "react";
import styles from "./ProductViews.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

const intervals =[
  "All",
  "No of users",
  "Activate Users with Daleel",
  "Active users without Daleel",
  "No of Sold Cards",
  "No of Share Info",
  "No of companies",
  "Total sales",
  "No of app download",
  "No of click social media",
  "No of click Facebook",
  "No of click Instgram",
  "No of click Linkedin",
  "No of click Twitter",
]

const ProductViews = () => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/DashboardStatistics`).then((res) => {
        if (res.status === 200) {
          setUsers(res.data.data);
        }
      });
    });
  }, []);
  const renderBars = () => {
    switch (sorting) {
      case "All":
        return (
          <>
            <Bar dataKey="users" fill="#8a1cf7d6" />
            <Bar dataKey="activate" fill="#32b56dd4" />
            <Bar dataKey="notactivate" fill="#f93614e0" />
            <Bar dataKey="sales" fill="#146ff9" />
            <Bar dataKey="sold" fill="#ffe29f" />
            <Bar dataKey="company" fill="#1872a2" />
            <Bar dataKey="share" fill="#196169" />
          </>
        );
      case "No of users":
        return <Bar dataKey="users" fill="#8a1cf7d6" />;
      case "Activate Users with Daleel":
        return <Bar dataKey="activate" fill="#32b56dd4" />;
      case "Active users without Daleel":
        return <Bar dataKey="notactivate" fill="#f93614e0" />;
      case "No of Sold Cards":
        return <Bar dataKey="sold" fill="#146ff9" />;
      case "No of companies":
        return <Bar dataKey="company" fill="#ffe29f" />;
      case "Total sales":
        return <Bar dataKey="sales" fill="#1872a2" />;
      case "No of Share Info":
        return <Bar dataKey="share" fill="#196169" />;
      default:
        return null;
    }
  };
  return (
    <Card
      className={styles.card}
      title="Users"
      classTitle="title-purple"
      head={
        <>
          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        </>
      }
    >
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={300}
            height={300}
            data={users}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5,
            }}
            barSize={100}
            barGap={10}
          >
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 13, fontWeight: "500", fill: "#CABDFF" }}
              padding={{ left: 10 }}
              
            />
            <YAxis
              // axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fontWeight: "500", fill: "#CABDFF" }}
              padding={{ left: 0 }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#fff",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 10,
                boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              labelStyle={{ fontSize: 16, fontWeight: "500", color: "#8a1cf7d6" }}
              cursor={{ fill: "#f3f2f3" }}
              backgroundColor={{ fill: "#8E59FF" }}
            />
            <Legend />
            {renderBars()}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default ProductViews;
