import React, { useState } from "react";
import axios from "axios";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import "reactjs-popup/dist/index.css";
import { useNavigate } from "react-router-dom";

const Entry = ({ onConfirm }) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setStatus(null);
    setLoading(true);

    try {
      await axios.get("/sanctum/csrf-cookie");
      const response = await axios.post(
        "/api/password/email",
        { email },
        {
          withCredentials: true,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );
      setStatus("Reset link sent successfully!");
      setTimeout(() => {
        navigate("/signin");
      }, 6000);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors([error.response.data.error]);
      } else {
        setErrors([error.response.data.error]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.entry}>
      <form className={styles.body} onSubmit={handleSubmit}>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="Your email"
          required
          icon="mail"
          onChange={handleEmailChange}
          value={email}
        />
        {errors.length > 0 && (
          <div className={styles.errors}>
            {errors.map((error, index) => (
              <div key={index} className={styles.error}>
                {error}
              </div>
            ))}
          </div>
        )}
        {status && <div className={styles.success}>{status}</div>}

        <button className={cn("button", styles.button)} type="submit" disabled={loading}>
          {loading ? "Loading..." : "Send Reset Link"}
        </button>
      </form>
    </div>
  );
};

export default Entry;
