import React, { useEffect, useState } from 'react';
import styles from "./AddCompany.module.sass";
import cn from "classnames";
import DivCard from "../../../components/CardDiv";
import TextInput from "../../../components/TextInput";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import swal from "sweetalert";

const EditCompany = ({ validUserIds }) => {
  const [Userinputs, setUser] = useState([]);
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const history = useNavigate();

  const handleInput = (e) => {
    setUser({ ...Userinputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/ViewUserToedit/${id}`).then((res) => {
        if (res.data.status === 200) {
          setUser(res.data.data);
        } else if (res.data.status === 404) {
          swal("Oops!", res.data.message, "error");
          history(-1);
        }
      });
    });
  }, [id]);

  const UpdateLink = (e) => {
    e.preventDefault();
    setLoading(false)
    const data = { ...Userinputs, };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.put(`api/UpdateUserAccount/${id}`, data).then((res) => {
        if (res.data.status === 200) {
          swal("Success!", "Account Updated Successfully", "success");
          setLoading(true)
          history(-1);
        } else if (res.data.status === 422) {
          swal("Oops!", "Fields is Required", "error");
          setLoading(true)
        }
      });
    });
  };
  return (
    <>
      <form onSubmit={UpdateLink} encType="multipart/form-data">
        <DivCard
          className={styles.card}
          title="Edit User"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
        >
          <TextInput
            className={styles.field}
            label="User Name "
            name="name"
            type="text"
            required
            onChange={handleInput}
            value={Userinputs.name}
          />
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Sector "
              name="sector"
              type="text"
              required
              onChange={handleInput}
              value={Userinputs.sector}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Phone Number"
              name="phone"
              placeholder={(Userinputs.user && Userinputs.user.phone) ?? "This User don't have a phone number"}
              type="number"
              onChange={handleInput}
              value={Userinputs.user && Userinputs.user.phone ? Userinputs.user.phone : null}
            />
          </div>

          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Expertise "
              name="expertise"
              type="text"
              required
              onChange={handleInput}
              value={Userinputs.expertise}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Bio"
              name="bio"
              type="text"
              required
              onChange={handleInput}
              value={Userinputs.bio}
            />
          </div>
          <button type="submit" disabled={!loading} className={cn("button", styles.button)}>{loading ? 'Edit User' : 'Loading...'}</button>
        </DivCard>
      </form>
    </>
  );
};

export default EditCompany;