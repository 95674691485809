import React, { useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import Icon from "../../../../../components/Icon";
import { ScaleLoader } from "react-spinners";


const Market = ({ items, loading, search }) => {

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };
  if (loading) {
    return (
      <center>
        <ScaleLoader color="#2a4f99" />
      </center>
    );
  } else {
    return (
      <>
        {items.length === 0 ? (
          <center>No Users in This company</center>
        ) : (
          <div className={styles.market}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Name</div>
                <div className={styles.col}>Email</div>
                <div className={styles.col}>Phone Number</div>
                <div className={styles.col}>Companies</div>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>View</div>

              </div>
              {items
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : (item.name || item.userprofiles.email) === null
                      ? ""
                      : item.name.toLowerCase().includes(search) ||
                      item.userprofiles.email.toLowerCase().includes(search);
                })
                .map((x, index) => (
                  <Row
                    item={x}
                    key={index}
                    up={items.length - index <= 2}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                  />
                ))}
            </div>
            <div className={styles.foot}>
              <button className={styles.arrow}>
                <Icon name="arrow-left" size="20" />
              </button>
              <button className={styles.arrow}>
                <Icon name="arrow-right" size="20" />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Market;
