const formatBankAccount = (accountNumber) => {
    // Remove any non-digit characters (like spaces, dashes, etc.)
    const cleanAccountNumber = accountNumber.replace(/\D/g, '');

    // Split the account number into groups of 4 digits
    const formattedAccountNumber = cleanAccountNumber.match(/.{1,4}/g)?.join(' ') || accountNumber;

    return formattedAccountNumber;
};

const vcard = (contact) => {
    // Split the name into first and last name, handle edge cases
    const [firstName = '', lastName = ''] = contact.name.split(' ');

    // Initialize vCard content
    let vCardContent = `BEGIN:VCARD\nVERSION:3.0\n`;

    // Add the formatted name (FN) and structured name (N)
    vCardContent += `FN:${contact.name}\n`;
    vCardContent += `N:${lastName};${firstName};;;\n`;

    // Add organization if available
    if (contact.company) {
        vCardContent += `ORG:${contact.company}\n`;
    }

    // Loop through links and add based on type
    contact.links?.forEach(link => {
        switch (link.link_type) {
            case 'email':
                vCardContent += `EMAIL:${link.url}\n`;
                break;
            case 'url':
                if (link.type === 'location') {
                    vCardContent += `ADR:;;${link.url};;;;\n`;
                } else if (link.link_type === 'url') {
                    vCardContent += `URL:${link.url}\n`; // Add link as a URL
                }
                break;
            case 'phone':
                vCardContent += `TEL:${link.url}\n`;
                break;
            case 'text':
                if (link.type === 'bank') {
                    // Format the bank account number before adding it to the vCard
                    const formattedBankAccount = formatBankAccount(link.url);
                    vCardContent += `NOTE:Bank Account: ${formattedBankAccount}\n`;
                }
                break;
            default:
                break;
        }
    });

    // Finalize vCard content
    vCardContent += `END:VCARD`;

    // Create a blob and trigger download as a .vcf file
    const blob = new Blob([vCardContent.trim()], { type: 'text/vcard' });
    const urlObject = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlObject;
    link.download = `${contact.name.replace(/[^a-zA-Z0-9]/g, '_')}.vcf`; // Sanitize name for filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(urlObject);
};

export default vcard;
