import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ScaleLoader } from "react-spinners"; // Import loading spinner

const items = [
  {
    title: "No of employee",
    icon: "activity",
    background: "#A694F040",
  },
  {
    title: "Total Lead generate",
    icon: "activity",
    background: "#A694F040",
  },
  {
    title: "Number Connections",
    icon: "activity",
    background: "#A694F040",
  },
  {
    title: "No of share info",
    counter: "128",
    icon: "activity",
    background: "#A694F040",
  },
  {
    title: "No card scanner",
    counter: "128",
    icon: "activity",
    background: "#A694F040",
  },
  {
    title: "Score",
    icon: "activity",
    background: "#A694F040",
  },
];

const Overview = ({ className }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(false); // Added loading state
  const cid = localStorage.getItem("company_id");
  const id = localStorage.getItem("id");

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const fetchData = () => {
    setLoading(true); // Set loading to true before fetching
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios
        .get(`api/countsDashboardTeamleader?cid=${cid}&id=${id}`, {
          params: { startDate, endDate },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data.data;
            const updatedItems = items.map((item) => {
              switch (item.title) {
                case "No of employee":
                  return { ...item, counter: data.no_employee };
                case "Total Lead generate":
                  return { ...item, counter: data.totalLead };
                case "Number Connections":
                  return { ...item, counter: data.no_connections };
                case "No of share info":
                  return { ...item, counter: data.no_shareinfo };
                case "No card scanner":
                  return { ...item, counter: data.no_scanned };
                case "Score":
                  return { ...item, counter: data.score };
                default:
                  return item;
              }
            });
            setItemsData(updatedItems);
          }
          setLoading(false); // Set loading to false after fetching
        })
        .catch(() => {
          setLoading(false); // Handle errors and set loading to false
        });
    });
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Title", "Counter"];
    const tableRows = [];

    itemsData.forEach((item) => {
      const itemData = [item.title, item.counter || "N/A"];
      tableRows.push(itemData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text("Overview Report", 14, 15);
    doc.save(`overview_report_${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-purple"
        head={
          <>
            <Link
              to="#"
              onClick={generatePDF}
              className={cn("button", styles.button)}
            >
              Extract
            </Link>
          </>
        }
      >
        {loading ? (
          <center>
            <ScaleLoader color="#2a4f99" />
          </center>
        ) : (
          <div className={`row ${styles.overview}`}>
            <div className={styles.list}>
              {itemsData.map((x, index) => (
                <div
                  className={`col-md-4 ${styles.item}`}
                  key={index}
                  style={{ backgroundColor: x.background }}
                >
                  <div className={styles.icon}>
                    <Icon name={x.icon} size="24" />
                  </div>
                  <div className={styles.line}>
                    <div className={styles.details}>
                      <div className={styles.category}>{x.title}</div>
                      <div className={styles.counter}>{x.counter}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;