import React from 'react'
import { images } from '../../ImagePath'

function Footer({ translate }) {
    return (
        <div className='footer flex-between'>
            <div className='box'>
                <img src={images.logo} className='icon' alt='Logo' />
                <div className='content'>
                    <p>{translate.daleelSubhead}</p>
                </div>
            </div>
            {/* Contact */}
            <div className='box'>
                <strong>{translate.contact}</strong>
                <div className='content content-2 flex-col-center'>
                    <a href="tel:+962798178491" className='footer-contact'>
                        <i className="bi bi-telephone footer-icon"></i>
                        <span>+962798178491</span>
                    </a>
                    <a href="mailto:daleel.nfc@gmail.com" className='footer-contact'>
                        <i className="bi bi-envelope footer-icon"></i>
                        <span>contact@daleelnfc.com</span>
                    </a>
                </div>
            </div>
            {/* Social Media Links */}
            <div className='box'>
                <strong>{translate.socialMedia}</strong>
                <div className='content content-3'>
                    <a href='https://www.facebook.com/profile.php?id=61564134667348&mibextid=rS40aB7S9Ucbxw6v'><i className="bi bi-facebook"></i></a>
                    <a href='https://www.instagram.com/daleelnfc?igsh=ZGlwanljYXhzN3p2'><i className="bi bi-instagram"></i></a>
                    <a href='https://wa.me/+966557891715'><i className="bi bi-whatsapp"></i></a>
                    <a href="mailto:contact@daleelnfc.com"><i className="bi bi-envelope"></i></a>
                </div>
            </div>

            {/* Download Links */}
            <div className='box'>
                <strong>{translate.download}</strong>
                <div className='content content-4'>
                    <div className='downloads-images'>
                        <a href='https://play.google.com/store/apps/details?id=com.daleelnfc.app&pcampaignid=web_share'><img src={images.googlePlay} alt='Google Play' /></a>
                        <a href='https://apps.apple.com/eg/app/daleelnfc/id6504637190'><img src={images.appStore} alt='App Store' /></a>
                        <a href='https://wa.me/+966557891715'><img src={images.getCard} alt='Get Card' /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
