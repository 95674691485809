import React from 'react'
import { images } from '../../ImagePath'

function About({translate}) {
    return (
        <div className="about-app">
            <div className="app-head">
                {translate.daleelHead}
                <span className="app-head-image"></span>
            </div>

            <span className='sub-head'>
                {translate.daleelSubhead}
            </span>

            <div className='app-buttons flex-row-center'>
                <a href='https://play.google.com/store/apps/details?id=com.daleelnfc.app&pcampaignid=web_share'><img src={images.googlePlay} alt='Google Play' /></a>
                <a href='https://apps.apple.com/eg/app/daleelnfc/id6504637190'><img src={images.appStore} alt='App Store' /></a>
                <a href='https://wa.me/+966557891715'><img src={images.getCard} alt='Get Card' /></a>
            </div>

            <div className='rounded'>
                <img src={images.frame1} alt='Frame 1' className='frame fr1' />
                <img src={images.frame2} alt='Frame 2' className='frame fr2' />
            </div>
        </div>
    )
}

export default About
