import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Account.module.sass";
import Item from "./Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import { images } from "../../../ImagePath";

const Account = ({ className }) => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const history = useNavigate();
  const [selectedImage, setSelectedImage] = useState(images.logo);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/ViewAccount/${id}`).then((res) => {
        if (res.status === 200) {
          setUserData(res.data.data);
          setSelectedImage(res.data.data.image || images.logo);
        } else if (res.data.status === 404) {
          swal("Oops!", res.data.message, "error");
          history(-1);
        }
        setLoading(false);
      });
    });
  }, [id, history]);

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setSelectedImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const updateProfilePicture = () => {
    if (selectedImage !== images.logo) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      axios.post(`api/updateProfilePicture/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).catch((error) => {
        console.error("Error updating profile picture:", error);
      });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.put(`api/UpdateAccount/${id}`, userData).then((res) => {
        if (res.data.status === 200) {
          updateProfilePicture();
          swal("Success!", "Account Updated Successfully", "success");
          history("/account");
        } else if (res.data.status === 422) {
          swal("Oops!", "Fields are required", "error");
        }
      });
    });
  };

  return (
    <div className={styles.account}>
      <div className={styles.accountChildren}>
        <Item className={cn(styles.card, className)} title="Edit" classTitle="title-purple">
          <form onSubmit={handleUpdate}>
            <div className={styles.profile}>
              <div className={styles.avatar}>
                <img src={selectedImage} alt="Avatar" />
              </div>
              <div className={styles.file}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="image"
                  accept="image/*"
                />
                <button type="button" className={cn("button", styles.button)}>
                  <Icon name="add" size="24" />
                  <span>Upload New Picture</span>
                </button>
              </div>
            </div>
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="Name"
                name="name"
                type="text"
                required
                value={userData.name}
                onChange={handleInputChange}
              />
              <TextInput
                className={styles.field}
                label="Email"
                name="email"
                type="email"
                required
                value={userData.email}
                onChange={handleInputChange}
              />
            </div>
            <button type="submit" className={cn("button", styles.button)}>
              {loading ? "Loading..." : "Update"}
            </button>
          </form>
        </Item>
      </div>
    </div>
  );
};

export default Account;
