import React, { useState } from "react";
import axios from "axios";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";

const Entry = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: {
      email: "",
      password: "",
      blocked: "",
    },
  });

  const handleInput = (e) => {
    setLogin({ ...loginInput, [e.target.name]: e.target.value, error_list: { ...loginInput.error_list, [e.target.name]: "" } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios
      .get("/sanctum/csrf-cookie")
      .then(() => {
        return axios.post("api/login", data, {
          withCredentials: true,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        });
      })
      .then((res) => {
        setLoading(false); // Stop loading when response is received

        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_role", res.data.role);
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("company_id", res.data.company_id);

          const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000; // 2 hours
          localStorage.setItem("expiration_time", expirationTime);

          switch (res.data.role) {
            case "admin":
              history("/");
              break;
            case "company":
              history("/company");
              break;
            case "sales":
              history("/sales");
              break;
            case "coor":
              history("/coor");
              break;
            case "logis":
              history("/logis");
              break;
            case "finance":
              history("/finance");
              break;
            case "Teamleader":
              history("/Teamleader");
              break;
            default:
              break;
          }
        } else if (res.data.status === 422) {
          setLogin({ ...loginInput, error_list: res.data.errors });
        } else if (res.data.status === 420) {
          setLogin({ ...loginInput, error_list: { blocked: res.data.message } });
        } else {
          setLogin({ ...loginInput, error_list: { email: res.data.message } }); // Display general error message
        }
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        setLoading(false);
      });
  };

  return (
    <div className={styles.entry}>
      <form className={styles.body} onSubmit={handleSubmit}>
        <div className={styles.subtitle}>Sign In with Open account</div>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="E-mail"
          required
          icon="mail"
          onChange={handleInput}
          value={loginInput.email}
        />

        <TextInput
          className={styles.field}
          name="password"
          type="password"
          placeholder="Password"
          required
          icon="lock"
          onChange={handleInput}
          value={loginInput.password}
        />
        <Link to="/forgetpassword" className={styles.field}> Forget Password? </Link>
        <span className={styles.error}>{loginInput.error_list.email || loginInput.error_list.blocked}</span>
        <button className={cn("button", styles.button)} disabled={loading} type="submit">
          {loading ? 'Loading...' : 'Continue'}
        </button>
      </form>
    </div>
  );
};

export default Entry;