import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../components/ModalProduct";

const Row = ({ up, item }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.name ?? 'x'}</div>
              <div className={styles.col}>
              <div className={styles.label}>Sector</div>
                {item.sector ?? 'x'}</div>
            </div>
          </div>
          <div className={styles.details}></div>
        </div>
        <div className={styles.col}>
        <div className={styles.label}> Email</div>
          {item.email ?? 'x'}</div>
        <div className={styles.col}>
        <div className={styles.label}>Phone Number</div>
          {item.phone ?? 'x'}</div>
        <div className={styles.col}>
        <div className={styles.label}>Company</div>
          {item.company ?? 'x'}</div>
        <div className={styles.col}>
        <div className={styles.label}>For</div>
          {item.allshare.name ?? 'x'}</div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};
export default Row;
