import React, { useState } from "react";
import axios from "axios";
import cn from "classnames";
import styles from "./Account.module.sass";
import Item from "./Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import DivCard from "../../../components/CardDiv"
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { images } from "../../../ImagePath";
const Account = ({ className }) => {
  const [Image, setImage] = useState([]);
  const [loading, setLoading] = useState(true)
  const [selectedImage2, setSelectedImage2] = useState(images.logo);
  const history = useNavigate();
  const [registerInput, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    error_list: [],
    sucess: "",
  });

  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false)

    const formData = new FormData();
    formData.append("name", registerInput.name);
    formData.append("email", registerInput.email);
    formData.append("password", registerInput.password);
    formData.append("role", registerInput.role);
    formData.append("image", Image.image);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("api/register", formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            console.log("success");
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            setLoading(true)
            swal('success', res.data.message, 'success')
            history('/account')
          } else if (res.data.status === 422) {
            const validationErrors = res.data.validation_errors;

            let errorMessage = '';
            for (const field in validationErrors) {
              if (validationErrors.hasOwnProperty(field)) {
                validationErrors[field].forEach(error => {
                  errorMessage += `${error}\n`;
                });
              }
            }
            swal('Error', errorMessage, 'error');
            setLoading(true)
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
            setLoading(true)
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          setLoading(true)
        });
    });
  };

  const handleImage = (event) => {
    setImage({ image: event.target.files[0] })
  }
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage2(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMerge = (event) => {
    handleFileChange2(event);
    handleImage(event);
  };

  return (
    <form
      className={styles.account}
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      <div className={styles.accountChildreen}>
        <Item
          className={cn(styles.card, className)}
          title="Create new Account"
          classTitle="title-purple" >
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={selectedImage2} alt="Avatar" />
            </div>
            <div className={styles.file}>
              <input type="file" onChange={handleMerge} name="image" accept="image/*" />
              <button className={cn("button", styles.button)} type="button">
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div>
          <div className={styles.fieldset}>
            <TextInput
              className={styles.field}
              label="Name"
              name="name"
              type="text"
              required
              onChange={handleInput}
              value={registerInput.name}
            />

            <label className={styles.label}>Type</label>
            <select
              id="Sector"
              className={styles.field}
              name="role"
              required
              onChange={handleInput}
              value={registerInput.role}
            >
              <option value="" label="Select"></option>
              <option value="admin" label="Admin"></option>
              <option value="sales" label="Sales"></option>
              <option value="coor" label="Coordinator"></option>
              <option value="logis" label="Logistics"></option>
              <option value="finance" label="Finance"></option>
            </select>
          </div>
        </Item>
      </div>

      <DivCard
        className={styles.card}
        title="Login"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.inputrow}>
          <TextInput
            className={`${styles.field} ${styles.rowfield}`}
            label="Email "
            name="email"
            type="email"
            required
            onChange={handleInput}
            value={registerInput.email}
          />
          <TextInput
            className={`${styles.field} ${styles.rowfield}`}
            label="Password"
            name="password"
            type="password"
            required
            onChange={handleInput}
            value={registerInput.password}
          />
        </div>
        {loading ?
          <button className={cn("button", styles.button)} type="submit">
            Create Account
          </button>
          :
          <button disabled className={cn("button", styles.button)} type="submit">
            Loading...
          </button>}
      </DivCard>
    </form>
  );
};

export default Account;
