import React, { useEffect, useState } from "react";
import styles from "./Account.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import Form from "../../components/Form";
import Market from "./Market";
import Table from "./Table";
import axios from "axios";
import { traffic } from "../../mocks/traffic";
import { viewers } from "../../mocks/viewers";
import Divcard from "../../components/CardDiv"
import Dropdown from "../../components/Dropdown";
const intervals = [
  "A",
  "Z",
]

const Account = () => {
  const navigation = ["Viewers"];
  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);
  const id = localStorage.getItem('id')
  const [filter, setFilter] = useState('A'); // Corrected the setter function name to setFilter
  
  useEffect(() => {
    fetchData();
  }, [filter]);

  const sortData = (data, filter) => {
    return data.sort((a, b) => {
      if (filter === 'A') {
        return a.profile.name.localeCompare(b.profile.name);
      } else if (filter === 'Z') {
        return b.profile.name.localeCompare(a.profile.name);
      }
      return 0;
    });
  };

  const fetchData = async () => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/GetAdminRoles?id=${id}`).then(res => {
        if (res.status === 200) {
          let sortedData = sortData(res.data.data, filter);
          setUsers(sortedData);
        }
        setloading(false)
      });
    });
  };

  return (
    <Divcard
      className={styles.card}
      title="Account"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={setFilter}
            options={intervals}
            small
          />

          <div className={styles.contacts}>
            <Link to="/addnewaccount" className={cn("button", styles.button)}>
              Add New Account
            </Link>
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Market items={users} search={search} loading={loading} />}
          {activeTab === navigation[1] && (
            <Table
              title="Traffic source"
              items={traffic}
            />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} />
          )}
        </div>
      </div>
    </Divcard>
  );
};

export default Account;