import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import ModalProduct from "../../../../../components/ModalProduct";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import swal from "sweetalert";
import { images } from "../../../../../ImagePath";
import { imageUrl } from "../../../../../config";

const Row = ({ item, loading, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const history = useNavigate();
  const id = localStorage.getItem('company_id')
  const ReturnAccount = (e) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You will Return this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, Return it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/UnArchiveCompanyUser/${item.id}`, { id: id }).then((res) => {
            if (res.data.status === 200) {
              Swal.fire("Returned!", "Account has been Returned.", "success");
              window.location.reload();
            } else if (res.data.status === 404) {
              swal('Warning', res.data.message, 'warning')
            }
          });
        });
      } else {
        Swal.fire("Cancelled", "Your Account is still Deleted :)", "error");
      }
    });
  };
  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={item.image ? `${imageUrl}/${item.image}` : images.logo} alt="Avatar" />
            </div>
            <div>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.profile.name ?? 'x'}</div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.profile.sector ?? 'x'}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email ?? 'x'}</div>

        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.phone ?? 'x'}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Teams</div>
          {item.profile.teams ?? 'x'}</div>

        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          <div className={styles.label}>Status</div>
          {item.profile.status == 'active' ? (
            <div className={cn("status-green", styles.status)}>Active</div>
          ) : (
            <div className={cn("status-red", styles.status)}>Deactive</div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Return</div>
          <div onClick={ReturnAccount} className={styles.details}>Return</div>{" "}
        </div>
        <div className={styles.col}>

        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
