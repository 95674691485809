import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Profile from "./Profile";
import MyLinks from "./MyLinks";
import Connections from "./Connection";
import ShareInfo from "./ShareInfo";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import DivCard from "../../components/CardDiv";
import { imageUrl } from "../../config";
import { images } from "../../ImagePath";

const navigation = ["My Links", "Connections", "Share Info"];

const Shop = () => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({
    links: [],
    share: [],
    connections: [],
    banner: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Combining all API calls into one useEffect
    const fetchData = async () => {
      try {
        const [profileRes, shareInfoRes, connectionsRes] = await Promise.all([
          axios.get(`api/GetUserProfile/${id}`),
          axios.get(`api/GetuserShareInfo/${id}`),
          axios.get(`api/ViewUserFollow/${id}`),
        ]);

        if (profileRes.data.status === 200) {
          const { data: profileData } = profileRes.data;
          setData(prevState => ({
            ...prevState,
            links: profileData,
            banner: profileData[0].banner,
          }));
        }

        if (shareInfoRes.data.status === 200) {
          setData(prevState => ({
            ...prevState,
            share: shareInfoRes.data.data,
          }));
        }

        if (connectionsRes.data.status === 200) {
          setData(prevState => ({
            ...prevState,
            connections: connectionsRes.data.data,
          }));
        }
      } catch (error) {
        console.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const backgroundImg = useMemo(() => {
    return data.banner ? `${imageUrl}/${data.banner}` : images.background;
  }, [data.banner]);

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <center>
          <ScaleLoader color="#2a4f99" />
          </center>
        </div>
      ) : (
        <><div className={styles.background}>
          <img src={backgroundImg} alt="Avatar" />
        </div><div className={styles.shop}>
            <DivCard className={styles.card}>
              <Profile Links={data.links} />
              <div className={styles.control}>
                <div className={styles.nav}>
                  {navigation.map((x, index) => (
                    <button
                      key={index}
                      className={cn(styles.link, {
                        [styles.active]: index === activeIndex,
                      })}
                      onClick={() => setActiveIndex(index)}
                    >
                      {x}
                    </button>
                  ))}
                </div>
              </div>

              <div className={styles.wrap}>
                {activeIndex === 0 && <MyLinks Links={data.links} id={id} />}
                {activeIndex === 1 && <Connections connections={data.connections} />}
                {activeIndex === 2 && <ShareInfo share={data.share} />}
              </div>
            </DivCard>
          </div></>
      )}
    </>
  );
};

export default Shop;