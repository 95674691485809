import React from "react";
import { Link } from "react-router-dom";
import "./error.css"; // Import custom CSS

function Page404() {
  return (
    <div className="error-page-container">
      <div className="error-page-content">
        <div className="error-icon">⚠️</div>
        <h1 className="error-title">404</h1>
        <p className="error-message">Oops! The page you're looking for doesn't exist.</p>
        <p className="error-description">
          It seems like the page you are trying to reach has been moved or no longer exists.
        </p>
        <Link to="/" className="error-btn">
          Go Back Home
        </Link>
      </div>
    </div>
  );
}

export default Page404;
