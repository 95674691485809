import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../../components/ModalProduct";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import axios from "axios";
import { images } from "../../../../../ImagePath";
import { imageUrl } from "../../../../../config";

const Row = ({ up, item }) => {
  const history = useNavigate();

  const id = localStorage.getItem('company_id')

  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  const archiveUser = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want Deleted this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, Deleted it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false

    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/archiveUser/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              Swal.fire("Deleted!", "Account has been Deleted.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        Swal.fire("Cancelled", "Your Account is still Safe :)", "error");
      }
    });
  };

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={item.profile.image ? `${imageUrl}/${item.profile.image}` : images.logo} alt="Avatar" />
            </div>
            <div>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.profile.name ?? 'x'}</div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.profile.teams ?? 'x'}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.phone ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {item.profile.status ?? 'x'}</div>
        <div className={styles.col} onClick={archiveUser}>
          <div className={styles.delete}>Delete</div>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};
export default Row;