import React, { useEffect, useState } from "react";
import styles from "./ListAccount.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import Market from "./Market";
import Table from "./Table";
import { traffic } from "../../../../mocks/traffic";
import { viewers } from "../../../../mocks/viewers";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import DivCard from "../../../../components/CardDiv";
import Dropdown from "../../../../components/Dropdown";
// Sorting
const intervals = [
  "A",
  "Z",
];
const ListAccount = () => {
  const navigation = ["Viewers"];
  const id = localStorage.getItem('id');
  const cid = localStorage.getItem('company_id');
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);
  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [filter, setFilter] = useState("A");

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/TeamleaderUsers?id=${id}&cid=${cid}`).then(res => {
        if (res.status === 200) {
          setUsers(res.data.content);
        }
        setloading(false)
      });
    });
  }, [])

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const sortData = () => {
    return users.slice().sort((a, b) => {
      const nameA = a.email?.toLowerCase();
      const nameB = b.email?.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA?.localeCompare(nameB) : nameB?.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB?.localeCompare(nameA) : nameA?.localeCompare(nameB);
      }
      return 0;
    });
  };

  return (
    <Card
      className={styles.card}
      title="User"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />
        </>
      }
    >
      {loading ? (
        <DivCard className={styles.card}>
          <center>
          <ScaleLoader color="#2a4f99" />
          </center>
        </DivCard>
      ) : (
        <div className={styles.products}>
          <div className={styles.wrapper}>
            {activeTab === navigation[0] && <Market items={sortData()} search={search} />}
            {activeTab === navigation[1] && (
              <Table
                title="Traffic source"
                items={traffic}
              />
            )}
            {activeTab === navigation[2] && (
              <Table title="Viewers" items={viewers} />
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default ListAccount;
