import React from 'react'
import Popup from 'reactjs-popup'

function Error({handleClose, isProfileNotFound, handleOpen}) {
    return (
        <Popup open={isProfileNotFound} position="right center" onOpen={handleOpen} onClose={handleClose}>
        <div className='for-error flex-col-center'>
          <span className='error-message'>Sorry, but no profile was found for this URL. Please contact support.</span>
          <div className='error-contact flex-row-center'>
            <a href="tel:+962798178491" className='footer-contact'>
              <i className="bi bi-telephone footer-icon"></i>
              <span>+962798178491</span>
            </a>

            <a href="mailto:contact@daleelnfc.com" className='footer-contact'>
              <i className="bi bi-envelope footer-icon"></i>
              <span>contact@daleelnfc.com</span>
            </a>
          </div>
        </div>
      </Popup>
    )
}

export default Error
