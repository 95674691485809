import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import Popup from "reactjs-popup";
import linkType from './LinkTypes';
import Footer from '../../components/Mainprofile/Footer';
import About from '../../components/Mainprofile/About';
import Error from '../../components/Mainprofile/Error';
import vcard from './Vcard';
import { translations } from '../../lang';
import { images } from '../../ImagePath';
import { imageUrl } from '../../config';
import './MainProfile.css';
import Loading from '../../components/Loading/loading';
import useDarkMode from 'use-dark-mode';

function MainProfile() {
  // Use States
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    sector: '',
    expertise: '',
    profile_id: '',
  });

  const [currentLang, setCurrentLang] = useState(() => { return localStorage.getItem('lang') || 'en' });
  const [profile, setProfile] = useState([]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [isProfileNotFound, setIsProfileNotFound] = useState(false);
  const [loading, setloading] = useState(true);
  const darkMode = useDarkMode(false);
  const [isAgreed, setIsAgreed] = useState(false);

  // Use Ref
  const shareInfoCloseRef = useRef(null);
  const alertMessage = useRef(null);

  // Constants Varaibles
  const { id } = useParams();
  const t = translations[currentLang];
  const modal = { background: "#fff" };
  const close = { background: "#000" };
  const arrowStyle = { visibility: "#hidden" };

  // Functions
  const handleOpen = () => {
    document.body.classList.add('no-scroll');
    const blur = document.body.querySelector('#blur');
    blur.setAttribute('class', 'blur');
  };

  const handleClose = () => {
    document.body.classList.remove('no-scroll');
    const blur = document.body.querySelector('#blur');
    blur.removeAttribute('class', 'blur');
    setIsAgreed(false)
    setErrors([]);
  };

  const handleChange = async (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const toggleLanguage = () => {
    const lang = currentLang === 'en' ? 'ar' : 'en';
    setCurrentLang(lang)
    localStorage.setItem('lang', lang)
  };

  const handleAgree = () => {
    setIsAgreed(!isAgreed);
  };

  // Axios
  useEffect(() => {
    axios.get(`api/main-profile/${id}`).then((response) => {
      setloading(false)
      setProfile(response.data.data);
    }).catch((error) => {
      if (error.response.status === 404) {
        console.error(t.profileNotFound); // translation for 404
      }
    });
  }, [id, t.profileNotFound]);

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    setSuccess(false);

    const updatedForm = { ...form, profile_id: id };

    try {
      const response = await axios.post('api/AddShareInfo', updatedForm);

      if (response.status === 200) {
        shareInfoCloseRef.current?.click();

        // Display success alert
        if (alertMessage.current) {
          alertMessage.current.classList.add('appear');
          setTimeout(() => {
            alertMessage.current.classList.remove('appear');
          }, 5000);
        }

        setSuccess(true);
        setErrors([]);
        setIsAgreed(false)
      }
    } catch (error) {
      // Handle errors
      const errorMessages = error.response?.data?.errors || ['An unexpected error occurred. Please try again.'];
      setErrors(errorMessages);
    }
  };

  return loading ? (<Loading />) : (
    <div className={`main-container ${currentLang === 'ar' ? 'ar' : ''}`}>

      <div className='navbar'>
        <label class="switch">
          <input type="checkbox" checked={darkMode.value} onChange={() => darkMode.toggle()} />
          <span class="slider"></span>
        </label>

        <div class="paste-button">
          <button class="button-custom">{currentLang === 'en' ? 'English' : 'Arabic'} &nbsp; <img src={`${currentLang === 'en' ? images.english : images.arabic}`} className='lang-icon' /> </button>
          <div class="dropdown-content">
            <a onClick={toggleLanguage}>{currentLang === 'en' ? 'Arabic' : 'English'} &nbsp; <img src={`${currentLang === 'en' ? images.arabic : images.english}`} className='lang-icon' /></a>
          </div>
        </div>
      </div>

      <div ref={alertMessage} className='alertMessage'> {success && t.sharedSuccessfully}</div>
      <div id='blur'></div>

      {/* First Popup for 404 Error */}
      <Error handleClose={handleClose} isProfileNotFound={isProfileNotFound} handleOpen={handleOpen} />

      {/* User Information */}
      {profile.map((item, index) => (
        <>
          <div className='portfolio' key={index}>
            <div className='images'>
              <div className='background'>
                <img src={item.banner ? `${imageUrl}/${item.banner}` : images.background} alt='background' />
              </div>

              <div className='profile'>
                <img src={item.image ? `${imageUrl}/${item.image}` : images.logo} alt='Profile' />
              </div>
            </div>

            <div className='info'>
              <div className='about flex-col-center'>
                <strong className='text-custom'>{item.name}</strong>
                <span>{item.sector}</span>
                <span className='light'>{item.bio}</span>
              </div>

              <div className='contact'>
                {item.links && item.links.length > 0 && (
                  <div className='contact-buttons flex-row-center'>
                    {item.links.some(link => link.link_type === 'phone') && (
                      <a href={`tel:+${item.links.find(link => link.link_type === 'phone').url}`} className='buttons buttons-circle'>
                        <i className="bi bi-telephone"></i>
                      </a>
                    )}
                    {item.links.some(link => link.link_type === 'email') && (
                      <a href={`mailto:${item.links.find(link => link.link_type === 'email').url}`} className='buttons buttons-circle'>
                        <i className="bi bi-envelope"></i>
                      </a>
                    )}
                  </div>
                )}

                {/* Shareinfo */}
                <div className='contact-buttons flex-col-center'>
                  {item.links.length > 0 && (
                    <button
                      className='buttons buttons-rectangle'
                      onClick={() => vcard({
                        name: item.name ?? '',
                        company: item.company,
                        links: item.links
                      })}>
                      {t.saveContact}
                    </button>
                  )}

                  <Popup trigger={<button className='buttons buttons-rectangle-2' style={{ backgroundColor: '#222332' }}>{t.shareInfo}</button>}
                    modal={false}
                    close
                    arrowStyle={arrowStyle}
                    onOpen={handleOpen} onClose={handleClose}
                    closeOnDocumentClick={false}
                    >

                    {(close) => (
                      <div className={`shareinfo ${currentLang === 'ar' && 'ar'}`}>
                        <button ref={shareInfoCloseRef} className='shareinfo-close' onClick={close}><i className="bi bi-x-circle"></i></button>
                        <form className='shareinfo-form' onSubmit={(e) => handleSubmit(e, item.id)}>
                          <input type='text' name='name' className='input' placeholder={t.name} onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.name : ''}</span>
                          <input type='email' name='email' className='input' placeholder={t.email} onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.email : ''}</span>
                          <input type='tel' name='phone' className='input' placeholder={t.phone} onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.phone : ''}</span>
                          <input type='text' name='sector' className='input' placeholder={t.sector} onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors.sector : ''}</span>
                          <input type='text' name='company' className='input' placeholder={t.company} onChange={handleChange} />
                          <span className='shareinfo-error'>{errors ? errors?.company : ''}</span>
                          <div className='terms'>
                            <input type="checkbox" class="ui-checkbox" onClick={(e) => handleAgree()} />
                            <h2>{t.termsAndConditions}</h2>
                            <p>
                              {t.termsIntro}
                            </p>
                            <Popup
                              trigger={
                                <p>
                                  {t.agreeToSave} <span className='show_more'>{t.showMore}</span>
                                </p>
                              }
                              position="right center"
                              modal={false}
                              close
                              closeOnDocumentClick={false}
                            >
                              {(close) => (
                                <div className="dialog">
                                  <button className="close" onClick={close}>×</button>
                                  <h2>{t.termsAndConditions}</h2>
                                  <p>{t.termsIntro}</p>
                                  <p>{t.consentToDataCollection}</p>
                                  <p>{t.authorizationForDataUsage}</p>
                                  <p>{t.userResponsibilityForSharedInfo}</p>
                                </div>
                              )}
                            </Popup>
                          </div>
                          <button type='submit' className='buttons buttons-rectangle' disabled={!isAgreed}>{t.save}</button>
                        </form>
                      </div>
                    )}
                  </Popup>
                </div>
                {/* Links */}
                <div className='contact-links'>
                  <header className='header'>{t.links}</header>
                  <div className='links'>
                    {item.links && item.links.length > 0 ? (
                      item.links.map((link) => (
                        <a href={`${link.link_type === 'email' ? `mailto:${link.url}` : link.link_type === 'phone' ? `tel:+${link.url}` : link.url}`} className='link' key={link.id}>
                          <div className='head-link'>
                            <i className={`bi bi-${linkType(link.type)} link-icon`}></i>
                            <div className='link-navigator'>
                              <span className='url'>{currentLang === 'ar' ? link.name_ar : link.name}</span>
                              <i className="bi bi-chevron-right"></i>
                            </div>
                          </div>
                        </a>
                      ))
                    ) : (
                      <span className='message message-link'>{t.links}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div></>
      ))}

      {/* Mobile App For Daleel */}
      <About translate={t} />

      {/* Footer */}
      <Footer translate={t} />
    </div>
  );
}

export default MainProfile;