let icon = '';

const linkType = (type) => {
    switch (type) {
        case 'mail':
            icon = 'envelope'
            break
        case 'phone':
            icon = 'telephone'
        case 'url':
            icon = 'link-45deg'
            break
        case 'location':
        case 'address':
            icon = 'geo'
            break
        default:
            icon = type;
    }
    return icon;
}

export default linkType
