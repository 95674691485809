import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import ModalProduct from "../../../../../../components/ModalProduct";
import Control from "../../Control";
import { Link } from "react-router-dom";
import { images } from "../../../../../../ImagePath";
import { imageUrl } from "../../../../../../config";

const Row = ({ item, up, cards }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={item.profile.image ? `${imageUrl}/${item.profile.image}` : images.logo} alt="Avatar" />
            </div>

            <div>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.profile.name}
              </div>

              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.profile.sector ?? 'x'}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email}
          <Control
            cards={cards}
            item={item}
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          />
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.phone ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Teams</div>
          {item.profile.teams ?? 'x'}</div>

        <div className={styles.col}>
          <div className={styles.label}>Score</div>
          {item.profile.score}</div>
        <div className={styles.col}>

          <div className={cn(
            { 'status-green': item.profile.status === 'active' },
            { 'status-red': item.profile.status === 'deactive' || item.profile.status === 'Blocked' }
          )}>
            {item.profile.status}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>View</div>
          <Link to={`personal-account/${item.profile.id}`} className={styles.list}>View</Link>{" "}
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;