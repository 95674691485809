import React, { useState } from "react";
import styles from "./AddTeamleader.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import { useNavigate } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import DivCard from "../../../../components/CardDiv";
import { images } from "../../../../ImagePath";
const AddCompany = () => {
  const [Image, setImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState(images.logo);
  const [loading, setLoading] = useState(true)
  const history = useNavigate();
  const [registerInput, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    teams: "",
    error_list: [],
    sucess: "",
  });
  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false)
    const formData = new FormData();
    formData.append("name", registerInput.name);
    formData.append("email", registerInput.email);
    formData.append("password", registerInput.password);
    formData.append("teams", registerInput.teams);
    formData.append("company_ID", localStorage.getItem("company_id"));
    formData.append("image", Image.image);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("api/newTeamleader", formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setRegister({
              ...registerInput,
              sucess: res.data.headers,
              error_list: [],
            });
            setLoading(true)
            swal("Success!", res.data.headers, "success");
            history("/company/company-teamleader");
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.headers,
            });
            setLoading(true)
          }
        })
        .catch((error) => {
          console.error("Error registering:");
        });
      setLoading(true)
    });
  };

  const handleImage = (event) => {
    setImage({ image: event.target.files[0] });
  };
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage2(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMerge = (event) => {
    handleFileChange2(event);
    handleImage(event);
  };

  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DivCard
          className={styles.card}
          title="Create new Team leader"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
        >
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={selectedImage2} alt="Avatar" />
            </div>
            <div className={styles.file}>
              <input
                type="file"
                onChange={handleMerge}
                name="image"
                accept="image/*"
              />
              <button className={cn("button", styles.button)} type="button">
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div>

          <TextInput
            className={styles.field}
            label=" Name "
            name="name"
            type="text"
            required
            onChange={handleInput}
            value={registerInput.name}
          />

          <TextInput
            className={styles.field}
            label="Team Name"
            name="teams"
            type="text"
            required
            onChange={handleInput}
            value={registerInput.teams}
          />
        </DivCard>

        <DivCard
          className={styles.card}
          title="Login"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
        >
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Email "
              name="email"
              type="email"
              required
              onChange={handleInput}
              value={registerInput.email}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Password"
              name="password"
              type="password"
              required
              onChange={handleInput}
              value={registerInput.password}
            />
          </div>
          <button className={cn("button", styles.button)} disabled={!loading}>{loading ? 'Create Account' : 'Loading...'}</button>
        </DivCard>
      </form>
    </>
  );
};

export default AddCompany;
