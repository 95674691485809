// lang.js
export const translations = {
  en: {
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    sector: 'Sector',
    company: 'Company',
    shareInfo: 'Share Info',
    saveContact: 'Save Contact',
    noLinks: 'No links found....',
    links: 'Links',
    save: 'Save',
    sharedSuccessfully: 'Shared your information successfully',
    profileNotFound: 'Profile not found',
    daleelHead: 'Daleel Mobile App Available Now',
    daleelSubhead: 'Find your info easily and save all your new connections in one place.',
    socialMedia: 'Social Media',
    contact: 'Contacts',
    save: 'Save',
    agreeToSave: 'Agree to Save',
    pleaseAgreeToSave: 'Please agree to save your information.',
    cancel: 'Cancel',
    agree: 'Agree',
    termsAndConditions: 'Terms and Conditions',
    termsIntro: 'Daleel Digital Cards platform is an innovative tool for digitizing and modernizing traditional paper business cards to be contemporary with the current times. Daleel aims to be a comprehensive platform for creating digital profiles instead of traditional paper cards, which can cause environmental damage and pollution.',
    consentToDataCollection: 'By pressing the "Share Info" button, you consent to the Daleel Digital Cards platform collecting and obtaining data and information shared under the Daleel platform which includes (phone number, name, email, affiliated institution, sector, etc.), regardless of your registration status within the Daleel Digital Cards platform.',
    authorizationForDataUsage: 'Additionally, you hereby grant Daleel authorization to utilize this gathered data and information for the purpose of sending advertisements and notifications.',
    userResponsibilityForSharedInfo: 'You acknowledge and agree that by sharing your information using the "Share Info" feature, you undertake to preserve the confidentiality of the shared information. You are responsible for safeguarding and not disclosing this information except with the consent of its owner and the Daleel Digital Cards platform.',
    acceptSubmit: 'Accept & Submit',
    cancelText: 'Cancel',
    showMore: 'Show more..',
  },

  ar: {
    name: 'الاسم',
    email: 'البريد الإلكتروني',
    phone: 'الهاتف',
    sector: 'القطاع',
    company: 'الشركة',
    shareInfo: 'مشاركة المعلومات',
    saveContact: 'حفظ جهة الاتصال',
    noLinks: 'لا توجد روابط....',
    links: 'الروابط',
    save: 'حفظ',
    sharedSuccessfully: 'تمت مشاركة معلوماتك بنجاح',
    profileNotFound: 'الملف الشخصي غير موجود',
    daleelHead: 'تطبيق دليل للجوال متاح الآن',
    daleelSubhead: 'اعثر على معلوماتك بسهولة واحتفظ بجميع اتصالاتك الجديدة في مكان واحد.',
    socialMedia: 'وسائل التواصل الاجتماعي',
    contact: 'تواصل',
    save: 'حفظ',
    agreeToSave: 'موافقة على الحفظ',
    pleaseAgreeToSave: 'يرجى الموافقة لحفظ معلوماتك.',
    cancel: 'إلغاء',
    agree: 'موافقة',
    termsAndConditions: 'الشروط والأحكام',
    termsIntro: 'منصة ديل لبطاقات الأعمال الرقمية هي أداة مبتكرة لتحويل وتحديث بطاقات الأعمال الورقية التقليدية لتواكب العصر الحالي. تهدف ديل إلى أن تكون منصة شاملة لإنشاء الملفات الشخصية الرقمية بدلاً من بطاقات الأعمال الورقية التقليدية، والتي يمكن أن تسبب ضرراً بيئياً وتلوثاً.',
    consentToDataCollection: 'بالضغط على زر "مشاركة المعلومات"، فإنك توافق على جمع منصة بطاقة ديل الرقمية للبيانات والمعلومات التي يتم مشاركتها ضمن منصة ديل، والتي تشمل (رقم الهاتف، الاسم، البريد الإلكتروني، المؤسسة المنتمية إليها، القطاع، إلخ)، بغض النظر عن حالتك في التسجيل ضمن منصة ديل الرقمية.',
    authorizationForDataUsage: 'بالإضافة إلى ذلك، فإنك تمنح ديل إذنًا لاستخدام هذه البيانات والمعلومات لأغراض إرسال الإعلانات والإشعارات.',
    userResponsibilityForSharedInfo: 'أنت تقر وتوافق على أنه عند مشاركة معلوماتك باستخدام ميزة "مشاركة المعلومات"، فإنك تتعهد بالحفاظ على سرية المعلومات التي تتم مشاركتها. أنت مسؤول عن حماية هذه المعلومات وعدم الكشف عنها إلا بموافقة صاحبها ومنصة ديل الرقمية.',
    acceptSubmit: 'قبول وإرسال',
    cancelText: 'إلغاء',
    showMore: 'عرض المزيد..',
  }
};