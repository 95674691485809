import React, { useEffect, useState } from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import ProductViews from "./ProductViews";
import axios from "axios";

const ProductsDashboard = () => {
  
  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <ProductViews />
        <div className={styles.info}></div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ProductsDashboard;
