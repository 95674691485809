import React, { useEffect, useState } from "react";
import styles from "./Users.module.sass";
import cn from "classnames";
import Form from "../../components/Form";
import Market from "./Market";
import Table from "./Table";
import axios from "axios";
import DivCard from "../../components/CardDiv";
import * as XLSX from 'xlsx';
import "jspdf-autotable";
import { ScaleLoader } from "react-spinners";
import Dropdown from "../../components/Dropdown";

const intervals = ["A", "Z"]

const Users = () => {
  const navigation = ["Viewers"];
  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filter, setfilter] = useState('A');

  useEffect(() => {
    Fetchdata();
  }, [filter]);

  const sortData = (data, filter) => {
    return data.sort((a, b) => {
      if (filter === 'A') {
        return a.name.localeCompare(b.name);
      } else if (filter === 'Z') {
        return b.name.localeCompare(a.name);
      }
      return 0;
    });
  };

  const Fetchdata = async () => {
    try {
      const res = await axios.get('api/GetAllUsers');
      if (res.status === 200) {
        let sortedData = sortData(res.data.data, filter);
        setUsers(sortedData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExtract = (e) => {
    e.preventDefault();
    const columns = [
      "Name",
      "Sector",
      "Email",
      "Phone Number",
      "Companies",
      "Status",
    ];

    const data = users.map((user) => [
      user.name,
      user.sector,
      user.userprofiles.email,
      user.userprofiles.phone,
      user.company,
      user.status,
    ]);

    // Add the column headers to the data array
    const sheetData = [columns, ...data];

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users Data");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, "users_data.xlsx");
  };

  return (
    <DivCard
      className={styles.card}
      title="Users"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={setfilter}
            options={intervals}
            small
          />
          <div className={styles.contacts}>
            <button className={cn("button", styles.button)} onClick={handleExtract}>
              Extract
            </button>
          </div>
        </>
      }
    >
      {loading ? (
        <center>
      <ScaleLoader color="#2a4f99" />
      </center>
      ) : (
        <div className={styles.products}>
          <div className={styles.wrapper}>
            {activeTab === navigation[0] && (
              <Market items={users} search={search} loading={loading} />
            )}
            {activeTab === navigation[1] && (
              <Table title="Traffic source" items={[]} />
            )}
            {activeTab === navigation[2] && (
              <Table title="Viewers" items={[]} />
            )}
          </div>
        </div>
      )}
    </DivCard>
  );
};

export default Users;
