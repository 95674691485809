import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";


const CompanyRoutes = () => {
  

    const isLogIN = localStorage.getItem("auth_token");
    const isRole = localStorage.getItem("auth_role");
   
    const history = useNavigate();
  
    
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 403) {
          history("/403");
        } else if (error.response.status === 404) {
          history("/404");
        }
        return Promise.reject(error);
      }
    );
  
    return isLogIN && isRole === 'company'  ? <Outlet /> : <Navigate to="/signin" />;
  };
export default CompanyRoutes
