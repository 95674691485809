import React, { useEffect, useState, useRef, createRef } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import Icon from "../../../../components/Icon";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { QRCodeCanvas } from "qrcode.react";

const Details = ({ className, values }) => {
  const url = "https://anshsaini.com";

  const getStatusColorClass = (status) => {
    switch (status) {
      case "active":
        return styles.active;
      case "notactive":
        return styles.notactive;
      default:
        return "";
    }
  };

  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getstatus, setGetStatus] = useState([]);
  const qrRefs = useRef([]);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/GetCompanyCards/${id}`).then((res) => {
        if (res.data.status === 200) {
          setSales(res.data.data);
        }
        setLoading(false);
      });
    });
  }, [id]);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/getTitleofcompanyCards/${id}`).then((res) => {
        if (res.data.status === 200) {
          setGetStatus(res.data.data);
        }
        setLoading(false);
      });
    });
  }, [id]);

  // URL copy function
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("URL copied");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };
  const generateQRCodeRefs = (index) => {
    qrRefs.current[index] = qrRefs.current[index] || createRef();
    return qrRefs.current[index];
  };

  // Download QR code function
  const downloadQRCode = (e, index) => {
    e.preventDefault(); // Prevent default behavior
    const canvas = qrRefs.current[index].current.querySelector("canvas");
    if (!canvas) throw new Error("<canvas> not found in the DOM");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <Card
            className={styles.card}
            title={getstatus.name}
            classTitle={cn("title-purple", styles.title)}
            classCardHead={styles.head}
            head={
              <>
                <Form
                  className={styles.form}
                  value={search}
                  setValue={setSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  type="text"
                  name="search"
                  icon="search"
                />
              </>
            }
          >
            <center>
              <ScaleLoader color="#2a4f99" />
            </center>
          </Card>
        </div>
      ) : (
        <Card
          className={styles.card}
          title={getstatus.name}
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
          head={
            <>
              <Form
                className={styles.form}
                value={search}
                setValue={setSearch}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                type="text"
                name="search"
                icon="search"
              />
            </>
          }
        >
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>User</div>
                <div className={styles.col}>URL</div>
                <div className={styles.col}>QR Code</div>
              </div>
              {sales.filter((item) => {
                return search.toLowerCase() === "" ? item : item.user === null ? ""
                  : item.user.toLowerCase().includes(search);
              })
                .map((x, index) => (
                  <div className={styles.row} key={index}>
                    <div className={cn(styles.col)}>
                      <span className={getStatusColorClass(x.status)}>
                        {x.status}
                      </span>
                    </div>

                    {x.user === null ?
                      <div className={styles.col}>{x.user}</div>
                      :
                      <Link to={`https://daleelnfc.com/ViewProfile/${x.url}`} className={styles.col}>{x.user}</Link>
                    }
                    <div className={styles.col}>
                      <div style={{ cursor: 'pointer' }}
                        onClick={() =>
                          copyToClipboard(
                            `https://daleelnfc.com/ViewProfile/${x.url}`
                          )
                        }
                      >
                        <Icon name="copy" size="30" className={styles.svgi} />
                      </div>

                    </div>


                    <div className={styles.col}>
                      <div
                        ref={generateQRCodeRefs(index)}
                        style={{
                          position: "absolute",
                          top: "-9999px",
                          left: "0px",
                          right: "0px"
                        }}
                      >
                        <QRCodeCanvas
                          level="H"
                          size={300}
                          value={`https://daleelnfc.com/ViewProfile/${x.qr_code}`}
                        />
                      </div>
                      <div className="my-5">
                        <button onClick={(e) => downloadQRCode(e, index)}>
                          <Icon name="download" size="30" className={styles.svgi} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default Details;
