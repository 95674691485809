import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./PayoutHistory.module.sass";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import Dropdown from "../../../components/Dropdown";

const intervals = [
  "A",
  "Z",
];
const PayoutHistory = ({ className, sales, loading }) => {
  const getStatusColorClass = (status) => {
    switch (status) {
      case "shipped":
        return styles.shipped;
      case "approved":
        return styles.approved;
      case "ready to ship":
        return styles.ready;
      case "under progress":
        return styles.under;
      case "delivered":
        return styles.delivered;
      default:
        return "";
    }
  };

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("A"); // Default to ascending
  const [sortedItems, setSortedItems] = useState(sales);

  useEffect(() => {
    setSortedItems(sortData(sales, filter));
  }, [sales, filter]);

  const sortData = (data, filter) => {
    return data.slice().sort((a, b) => {
      if (filter === 'A') {
        return a.name.localeCompare(b.name);
      } else if (filter === 'Z') {
        return b.name.localeCompare(a.name);
      }
      return 0;
    });
  };

  const handleExtract = (e) => {
    e.preventDefault(); // Prevent default behavior

    const columns = [
      "Client_Name",
      "Seller_Name",
      "Type",
      "Status",
      "No of cards",
      "Earnings",
      "Transaction reference",
    ];

    const data = sortedItems.map((user) => [
      user.name,
      user.seller_name,
      user.type,
      user.status,
      user.num_card,
      user.earnings,
      user.reference,
    ]);

    const worksheetData = [columns, ...data];

    // Create a worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Data");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, "sales_data.xlsx");
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };


  return (
    <Card
      className={styles.card}
      title="Sales"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />
          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />
          <div className={styles.contacts}>
            <button
              className={cn("button", styles.button)}
              onClick={handleExtract}
            >
              Extract
            </button>
          </div>

        </>
      }
    >
      {loading ? (
        <center>
      <ScaleLoader color="#2a4f99" />
      </center>
      ) : (
        (sortedItems.length === 0) ? (
          <center>No Sales</center>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={`${styles.row} ${styles.rowtable}`}>
                <div className={styles.col}>For</div>
                <div className={styles.col}>Sales</div>
                <div className={styles.col}>Type</div>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>Number of cards</div>
                <div className={styles.col}>Earnings</div>
                <div className={styles.col}>Transaction reference</div>
                <div className={styles.col}>Details</div>
              </div>

              {sortedItems
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.name.toLowerCase().includes(search) ||
                    item.seller_name.toLowerCase().includes(search);
                })
                .map((x, index) => (
                  <div className={styles.row} key={index}>

                    <div className={styles.col}>
                      <div className={styles.label}>For</div>
                      {x.name}
                    </div>

                    <div className={styles.col}>
                      <div className={styles.label}>Sales</div>
                      {x.seller_name}
                    </div>

                    <div className={styles.col}>
                      <div className={styles.label}>Type</div>
                      {x.type}
                    </div>

                    <div className={styles.col}>
                      <div className={styles.label}>Status</div>
                      {x.status}
                    </div>

                    <div className={styles.col}>
                      <div className={styles.label}>Sales</div>
                      {x.num_card}
                    </div>

                    <div className={styles.col}>
                      <div className={styles.label}>Number of cards</div>
                      {x.earnings}
                    </div>

                    <div className={styles.col}>
                      <div className={styles.label}>Transaction reference</div>
                      {x.reference}
                    </div>

                    <div className={`${styles.col} `}>
                      <div className={styles.label}>Details</div>
                      <Link to={`/sales-approved/${x.id}`} className={styles.details}>
                        Details
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      )}
    </Card>
  );
};

export default PayoutHistory;
