import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import Icon from "../../../../components/Icon";
import { useParams } from "react-router-dom";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import { RingLoader, ScaleLoader } from "react-spinners";
import Divcard from "../../../../components/CardDiv";

const Details = ({ className }) => {
  const getStatusColorClass = (status) => {
    switch (status) {
      case "active":
        return styles.active;
      case "notactive":
        return styles.notactive;
      default:
        return "";
    }
  };

  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [sales, setSales] = useState([]);
  const [getstatus, setgetstatus] = useState([]);
  const [loading, setloading] = useState(true);
  const [copying, setCopying] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const qrRef = useRef();

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/getSalesCards/${id}`).then((res) => {
        if (res.data.status === 200) {
          setSales(res.data.data);
        }
        setloading(false);
      });
    });
  }, [id]);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/getStatusCard/${id}`).then((res) => {
        if (res.data.status === 200) {
          setgetstatus(res.data.data);
        }
        setloading(false);
      });
    });
  }, [id]);

  const copyToClipboard = (text) => {
    setCopying(true);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("URL copied");
        setCopying(false);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        setCopying(false);
      });
  };

  const downloadQRCode = (e) => {
    e.preventDefault();
    setDownloading(true);
    const canvas = qrRef.current.querySelector("canvas");
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setDownloading(false);
  };

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <Card
            className={styles.card}
            title={getstatus.name}
            classTitle={cn("title-purple", styles.title)}
            classCardHead={styles.head}
            head={
              <>
                <Form
                  className={styles.form}
                  value={search}
                  setValue={setSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  type="text"
                  name="search"
                  icon="search"
                />
              </>
            }
          >
            <center>
            <ScaleLoader color="#2a4f99" />
            </center>
          </Card>
        </div>
      ) : (
        <Divcard
          className={styles.card}
          title={getstatus.name}
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
          head={
            <>
              <Form
                className={styles.form}
                value={search}
                setValue={setSearch}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                type="text"
                name="search"
                icon="search"
              />
            </>
          }
        >
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.col}>Status</div>
                <div className={styles.col}>User</div>
                <div className={styles.col}>URL</div>
                <div className={styles.col}>QR Code</div>
              </div>
              {sales
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.user === null
                    ? ""
                    : item.user.toLowerCase().includes(search);
                })
                .map((x, index) => (
                  <div className={styles.row} key={index}>
                    <div className={cn(styles.col)}>
                      <span className={getStatusColorClass(x.status)}>
                        {x.status}
                      </span>
                    </div>
                    <div className={styles.col}>{x.user}</div>
                    <div className={styles.col}>
                      <button
                        onClick={() =>
                          copyToClipboard(`https://daleelnfc.com/ViewProfile/${x.url}`)
                        }
                        disabled={copying}
                      >
                        <Icon name="copy" size="30" className={styles.svgi} />
                      </button>
                    </div>
                    <div className={styles.col}>
                      <div ref={qrRef} style={{ position: "absolute", top: "-9999px", left: "0px", right: "0px" }}>
                        <QRCodeCanvas
                          level="H"
                          size={300}
                          value={`https://daleelnfc.com/ViewProfile/${x.qr_code}`}
                        />
                      </div>
                      <div className="my-5">
                        <button
                          onClick={downloadQRCode}
                          disabled={downloading}
                        >
                          <Icon name="download" size="30" className={styles.svgi} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Divcard>
      )}
    </>
  );
};

export default Details;
