import React, { useState } from "react";
import cn from "classnames";
import styles from "./RestPassword.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import { images } from "../../ImagePath";

const RestPassword = () => {
  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <Link className={styles.logo} to="/">
            <img src={images.logo} alt="Login" />
          </Link>
        </div>
        <div className={styles.wrapper}>
          <div className={cn("h2", styles.title)}>Reset password</div>
          {visible ? <Entry onConfirm={() => setVisible(false)} /> : <Code />}
        </div>
      </div>
    </div>
  );
};

export default RestPassword;
