import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../components/ModalProduct";
import { Link } from "react-router-dom";
import Control from "../../Control";
import cn from "classnames";
import { imageUrl } from "../../../../config";
import { images } from "../../../../ImagePath";


const Row = ({ item, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={item.company_info.profile.image ? `${imageUrl}/${item.company_info.profile.image}` : images.logo} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.company_info.profile.name}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.company_info.email}
          <Control
            item={item}
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          />
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {item.hold === 1 ? (
            <div className={cn("status-red", styles.status)}>Holded</div>
          ) : (
            <div className={cn("status-green", styles.status)}>Active</div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Users</div>
          {item.num_users ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Cards</div>
          {item.num_cards ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>For</div>
          {item.seller_name ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>List of employees</div>
          <Link to={`/list-account/${item.id}`} className={styles.list} >List of employees</Link>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;