export const images = {
    logo: '/images/Logos/Logo.png',
    background: '/images/Logos/Background.png',
    arabic: '/images/Icons/Arabic.svg',
    english: '/images/Icons/English.svg',
    frame1: '/images/Mainprofile/Frame1.png',
    frame2: '/images/Mainprofile/Frame2.png',
    getCard: '/images/Mainprofile/Get-Card.svg',
    googlePlay: '/images/Mainprofile/Google-Play.svg',
    appStore: '/images/Mainprofile/App-Store.svg',
}