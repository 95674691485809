import React, { useEffect, useState } from "react";
import styles from "./ShareInfo.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Market from "./Market";
import Table from "./Table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { traffic } from "../../mocks/traffic";
import { viewers } from "../../mocks/viewers";
import axios from "axios";
import Dropdown from "../../components/Dropdown";

const intervals = [
  "A",
  "Z",
];
const ShareInfo = () => {
  const navigation = ["Viewers"];
  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc"); // Default to ascending
  const [filter, setFilter] = useState("A"); // Default filter

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/GetAllShareInfo`).then((res) => {
        if (res.status === 200) {
          setUsers(res.data.data);
        }
        setLoading(false);
      });
    });
  }, []);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const sortData = () => {
    return users.slice().sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
      }
      return 0;
    });
  };
  // PDF FUNCTION
  const handleExtract = (e) => {
    e.preventDefault(); // Prevent default behavior
    setDownloading(true); // Set downloading state to true

    const doc = new jsPDF();
    const columns = [
      "Name",
      "Sector",
      "Email",
      "Phone Number",
      "Companies",
      "From",
    ];
    const data = sortData().map((user) => [
      user.name,
      user.sector,
      user.email,
      user.phone,
      user.company,
      user.allshare.name,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
    });

    doc.save("Shareinfo_data.pdf");
    setDownloading(false); // Set downloading state to false
  };

  return (
    <Card
      className={styles.card}
      title="Share info"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />
          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />
          <div className={styles.contacts}>
            <button
              className={cn("button", styles.button)}
              onClick={handleExtract}
              disabled={downloading} // Disable button while downloading
            >
              Extract
            </button>
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && (
            <Market items={sortData()} loading={loading} search={search} toggleSortDirection={toggleSortDirection} />
          )}
          {activeTab === navigation[1] && (
            <Table title="Traffic source" items={traffic} />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShareInfo;
