import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import ModalProduct from "../../../../components/ModalProduct";
import { Link } from "react-router-dom";
import Control from "../../Control";
import { imageUrl } from "../../../../config";
import { images } from "../../../../ImagePath";

const Row = ({ item, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={item.image ? `${imageUrl}/${item.image}` : images.logo} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.name ?? 'x'}</div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.sector ?? 'x'}</div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.userprofiles.email ?? 'x'}
          <Control
            item={item}
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.userprofiles.phone ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Company</div>
          {item.company ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {item.status === 'deactive' || item.status === 'Blocked' ? (
            <div className={cn("status-red", styles.status)}>{item.status}</div>
          ) : (
            <div className={cn("status-green", styles.status)}>{item.status}</div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>View</div>
          <Link to={`/shop/${item.id}`} className={styles.list}>View</Link>
        </div>
      </div>
      <ModalProduct visible={visibleModalProduct} onClose={() => setVisibleModalProduct(false)} />
    </>
  );
};
export default Row;