import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./MyLinks.module.sass";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";
import { Switch } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import { Tooltip } from "react-tooltip";
const items = [
  {
    title: "Views",
    icon: "user",
    background: "#A694F040",
  },
  {
    title: "Total clicks",
    icon: "user",
    background: "#3CBEA940",
  },
  {
    title: "Share info",
    icon: "user",
    background: "#FFD88D40",
  },
  {
    title: "Save contacts",
    icon: "user",
    background: "#FF6A5540",
  },
  {
    title: "New following",
    icon: "user",
    background: "#B1E5FC40",
  },
];

const MyLinks = ({ className, Links, id }) => {
  const [itemss, setItems] = useState([]);
  const [primaryLink, setPrimaryLink] = useState(null);
  const [hiddenLinks, setHiddenLinks] = useState({});
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/GetUserCounts/${id}`).then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const updatedItems = items.map((item) => {
            switch (item.title) {
              case "Views":
                return { ...item, counter: data.users };
              case "Total clicks":
                return { ...item, counter: data.clicks };
              case "Share info":
                return { ...item, counter: data.share };
              case "Save contacts":
                return { ...item, counter: data.save_contact };
              case "New following":
                return { ...item, counter: data.following };
              default:
                return item;
            }
          });
          setItems(updatedItems);
        }
        setLoading(false);
      }).catch(() => setLoading(false));
    });
  }, [id]);

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <center>
            <ScaleLoader color="#2a4f99" />
          </center>
        </div>
      ) : (
        <>
          <Card className={cn(styles.card, className)} classTitle="title-purple">
            <div className={`row ${styles.overview}`}>
              <div className={styles.list}>
                {itemss.map((x, index) => (
                  <div
                    className={`col-md-4 ${styles.item}`}
                    key={index}
                    style={{ backgroundColor: x.background }}
                  >
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="24" />
                    </div>
                    <div className={styles.line}>
                      <div className={styles.details}>
                        <div className={styles.category}>{x.title}</div>
                        <div className={styles.counter}>{x.counter}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <TooltipGlodal />
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Type</div>
              <div className={styles.col}>Link Name</div>
              <div className={styles.col}>Clicks</div>
              <div className={styles.col}>URL</div>
              <div className={styles.col}>Primary</div>
              <div className={styles.col}>Hide</div>
            </div>
            {Links.map((user) =>
              user.links.map((link) => {
                const isPrimary = primaryLink === link.id;
                const isHidden = hiddenLinks[link.id];
                return (
                  <div className={styles.row} key={link.id}>
                    <div className={styles.col}>
                      <div className={styles.label}>Type</div>
                      {link.type}
                    </div>
                    <div className={styles.col}>
                      <div className={styles.label}>Link Name</div>
                      {link.name}
                    </div>
                    <div className={styles.col}>
                      <div className={styles.label}>Clicks</div>
                      {link.clicks}
                    </div>
                    <div className={styles.col}>
                      <div className={styles.label}>URL</div>
                      <Link to={link.url} className={`${styles.edit}`}>
                        {link.url}
                      </Link>
                    </div>
                    <div className={styles.col}>
                      <Tooltip title={isPrimary ? "Primary URL" : "Set as primary"}>
                        <Switch defaultChecked={link.primary} disabled />
                      </Tooltip>
                    </div>
                    <div className={styles.col}>
                      <Tooltip title={isHidden ? "Hidden" : "Hide URL"}>
                        <Switch defaultChecked={link.hide} disabled />
                      </Tooltip>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MyLinks;