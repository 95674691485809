import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import { Link } from "react-router-dom";
import { imageUrl } from "../../../../../config";
import { images } from "../../../../../ImagePath";

const Profile = ({ Links }) => {
  return (
    <div>
      {Links.map((x, index) => (
        <div className={styles.profile}>
          <div className={styles.details}>
            <div className={styles.avatar}>
              <img src={x.image ? `${imageUrl}/${x.image}` : images.logo} alt="Avatar" />
            </div>
            <div className={styles.wrap}>
              <div className={cn("h4", styles.man)}>{x.name}</div>
              <div className={styles.info}>{x.sector}</div>
            </div>
          </div>
          <div className={styles.contacts}>
            <Link to={`/company/company-newlinks/${x.id}`} className={cn("button", styles.button)}>
              Add New Link
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profile;
