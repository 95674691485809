import React from "react";
import "./error.css";
function Page403() {
  return (
    <div className="error-page-container">
      <div className="error-page-content">
        <div className="error-icon">⛔</div>
        <div className="error-title">403</div>
        <div className="error-message">Sorry, You Are Not Authorized!</div>
        <div className="error-description">
          It seems you don’t have permission to access this page.
        </div>
        <a href="/" className="error-btn">Go Back Home</a>
      </div>
    </div>
  );
}

export default Page403;
